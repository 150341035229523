<!-- eslint-disable no-unused-vars -->
<template>
  <b-card class="text-left">
    <validation-observer ref="formUser">
      <b-form>
        <b-card-header>
          <span class="d-flex align-items-center">
            <feather-icon icon="FileTextIcon" size="20" />
            <h2 class="mb-0 ml-50">Informações básicas</h2>
          </span>
        </b-card-header>
        <!-- Código do projeto -->
        <b-row
          class="my-1 m-2 pl-2 pr-2 text-left d-flex justify-content-center"
        >
          <b-col cols="12" xs="12" sm="12" md="12" lg="2">
            <b-form-group>
              <label
                for="project_code"
                class="text-center font-weight-bold text-primary"
              >
                Cód do projeto :
              </label>
              <validation-provider
                #default="{ errors }"
                :custom-messages="{ required: 'Este campo é obrigatório' }"
                rules="required"
              >
                <BFormInput
                  id="project_code"
                  type="text"
                  v-model="item.project_code"
                ></BFormInput>

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12" xs="12" sm="12" md="12" lg="6">
            <b-form-group>
              <label
                for="project_name"
                class="text-center font-weight-bold text-primary"
              >
                Nome do projeto :
              </label>
              <validation-provider
                #default="{ errors }"
                :custom-messages="{ required: 'Este campo é obrigatório' }"
                rules="required"
              >
                <BFormInput
                  id="project_name"
                  type="text"
                  v-model="item.project_name"
                ></BFormInput>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12" xs="12" sm="12" md="12" lg="4">
            <label
              for="project_name"
              class="text-center font-weight-bold text-primary"
            >
              Tipo de projeto :
            </label>
            <v-select
              v-model="item.project_type_id"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              placeholder="Selecione"
              :options="project_type_arr"
              :reduce="(option) => option.id"
              label="name"
            >
              <div slot="no-options">
                Desculpe, mas o projeto não tem etapa!
              </div>
              <template #list-header>
                <li
                  class="add-new-header d-flex align-items-center my-50 cursor-pointer"
                  @click="$router.push({ name: 'tipo-projeto' })"
                >
                  <feather-icon icon="PlusIcon" size="16" />
                  <span class="align-middle ml-25"
                    >Add Novo tipo de Projeto</span
                  >
                </li>
              </template>
            </v-select>
          </b-col>
        </b-row>

        <b-row
          class="my-1 m-2 pl-2 pr-2 text-left d-flex justify-content-center"
        >
          <b-col cols="12" xs="12" sm="12" md="12" lg="5">
            <b-form-group>
              <label
                for="client_name"
                class="text-center font-weight-bold text-primary"
              >
                Nome do cliente :
              </label>
              <validation-provider
                #default="{ errors }"
                :custom-messages="{ required: 'Este campo é obrigatório' }"
                rules="required"
              >
                <BFormInput
                  id="client_name"
                  type="text"
                  v-model="item.client_name"
                ></BFormInput>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12" xs="12" sm="12" md="6" lg="3">
            <b-form-group>
              <label class="text-center font-weight-bold text-primary"
                >Tipo de Pessoa:</label
              >
              <b-form-radio-group v-model="item.project_type">
                <b-form-radio value="pessoa_fisica">Pessoa Física</b-form-radio>
                <b-form-radio value="pessoa_juridica"
                  >Pessoa Jurídica</b-form-radio
                >
              </b-form-radio-group>
            </b-form-group>
          </b-col>

          <b-col cols="12" xs="12" sm="12" md="6" lg="4">
            <b-form-group>
              <label
                for="document"
                class="text-center font-weight-bold text-primary"
              >
                {{ item.project_type === 'pessoa_fisica' ? 'CPF' : 'CNPJ' }}:
              </label>
              <b-form-input
                v-if="item.project_type === 'pessoa_fisica'"
                id="document"
                v-model="item.document"
                placeholder="Digite o CPF"
                v-mask="'###.###.###-##'"
              ></b-form-input>
              <b-form-input
                v-else
                id="document"
                v-model="item.document"
                placeholder="Digite o CNPJ"
                v-mask="'##.###.###/####-##'"
              ></b-form-input>
              <!--  -->
              <!-- <BFormInput
              id="document"
              type="text"
              v-model="item.document"
            ></BFormInput> -->
            </b-form-group>
          </b-col>
        </b-row>

        <!-- Endereço -->
        <b-row
          class="my-1 m-2 pl-2 pr-2 text-left d-flex justify-content-center"
        >
          <b-col cols="12" xs="12" sm="12" md="12" lg="12">
            <b-form-group>
              <label for="description" class="font-weight-bold text-primary"
                >Endereço :</label
              >
              <BFormTextarea
                id="description"
                type="textarea"
                v-model="item.address"
              ></BFormTextarea>
            </b-form-group>
          </b-col>
        </b-row>

        <!-- Nome do contato -->
        <b-row
          class="my-1 m-2 pl-2 pr-2 text-left d-flex justify-content-center"
        >
          <b-col cols="12" xs="12" sm="12" md="4" lg="4">
            <b-form-group>
              <label
                for="contact_name"
                class="text-center font-weight-bold text-primary"
              >
                Nome do contato :
              </label>
              <BFormInput
                id="contact_name"
                type="text"
                v-model="item.contact_name"
              ></BFormInput>
            </b-form-group>
          </b-col>

          <b-col cols="12" xs="12" sm="12" md="4" lg="4">
            <b-form-group>
              <label
                for="contact_document"
                class="text-center font-weight-bold text-primary"
              >
                CPF do contato :
              </label>
              <BFormInput
                id="contact_document"
                type="text"
                v-mask="'###.###.###-##'"
                v-model="item.contact_document"
              ></BFormInput>
            </b-form-group>
          </b-col>

          <b-col col xs="12" sm="12" md="4" lg="4">
            <b-form-group>
              <label
                for="contact_phone"
                class="text-center font-weight-bold text-primary"
              >
                Telefone do contato :
              </label>
              <BFormInput
                id="contact_phone"
                type="tel"
                v-model="item.contact_phone"
              ></BFormInput>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row
          class="my-1 m-2 pl-2 pr-2 text-left d-flex justify-content-center"
        >
          <b-col cols="12" xs="12" sm="12">
            <b-form-group>
              <label
                for="project_description"
                class="font-weight-bold text-primary"
                >Descrição do projeto :</label
              >
              <BFormTextarea
                id="project_description"
                type="textarea"
                v-model="item.project_description"
              ></BFormTextarea>
            </b-form-group>
          </b-col>
        </b-row>

        <!-- Prazo -->
        <b-row
          class="my-1 m-2 pl-2 pr-2 text-left d-flex justify-content-center"
        >
          <b-col cols="12" xs="12" sm="12" md="6" lg="3">
            <b-form-group>
              <label
                for="deadline"
                class="text-center font-weight-bold text-primary"
              >
                Prazo:
              </label>
              <b-input-group>
                <BFormInput
                  id="deadline"
                  type="date"
                  v-model="item.deadline"
                ></BFormInput>
              </b-input-group>
            </b-form-group>
          </b-col>

          <b-col cols="12" xs="12" sm="12" md="6" lg="3">
            <b-form-group>
              <label for="date" class="font-weight-bold text-primary"
                >Data de inicio :</label
              >
              <BFormInput
                id="start_date"
                type="date"
                v-model="item.start_date"
              ></BFormInput>
            </b-form-group>
          </b-col>

          <b-col cols="12" xs="12" sm="12" md="6" lg="3">
            <b-form-group>
              <label for="contract_date" class="font-weight-bold text-primary"
                >Data do contrato :</label
              >
              <BFormInput
                id="contract_date"
                type="date"
                v-model="item.contract_date"
              ></BFormInput>
            </b-form-group>
          </b-col>

          <b-col cols="12" xs="12" sm="12" md="6" lg="3">
            <b-form-group>
              <label for="finish_date" class="font-weight-bold text-primary"
                >Previsão de conclusão :</label
              >
              <BFormInput
                id="finish_date"
                type="date"
                v-model="item.finish_date"
              ></BFormInput>
            </b-form-group>
          </b-col>
        </b-row>

        <hr />
        <b-card-header>
          <span class="d-flex align-items-center">
            <feather-icon icon="PieChartIcon" size="20" />
            <h2 class="mb-0 ml-50">Dados de planejamento</h2>
          </span>
        </b-card-header>

        <b-row class="frame">
          <h3 class="my-1 m-2 pl-2 pr-2 text-left d-flex">
            1. Alocar as fases do projeto
          </h3>
          <b-row
            class="my-1 m-2 pl-2 pr-2 text-left d-flex justify-content-center"
          >
            <b-col cols="12" xs="12" sm="12" md="6" lg="6">
              <div class="selected-items-container mb-1">
                <h5 class="header">Fases padrão (selecionar)</h5>
                <ul class="item-list">
                  <li
                    v-for="(item, index) in availableItems"
                    :key="index"
                    @click="selectItem(item)"
                    class="item"
                  >
                    {{ item.name }}
                  </li>
                </ul>
              </div>
            </b-col>

            <b-col cols="12" xs="12" sm="12" md="6" lg="6">
              <div class="selected-items-container">
                <h5 class="header">Fases selecionadas</h5>
                <ul class="item-list">
                  <label v-if="selectedItems.length < 1" class="placeholder">
                    Escolha da lista ou insira manualmente
                  </label>
                  <li
                    v-for="(item, index) in selectedItems"
                    :key="index"
                    class="item"
                  >
                    {{ item.name }}
                    <!-- Pill para o botão "x" -->
                    <span class="pill" @click.stop="deselectItem(item)">
                      ×
                    </span>
                  </li>
                </ul>
              </div>
            </b-col>

            <b-col cols="12" xs="12" sm="12" md="10" lg="10">
              <b-form-group>
                <label
                  for="contact_name"
                  class="text-center font-weight-bold text-primary"
                >
                  Fase personalizada:
                </label>
                <BFormInput
                  placeholder="Digite o nome da fase do projeto"
                  id="contact_name"
                  type="text"
                  v-model="personalStage"
                ></BFormInput>
              </b-form-group>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="2" lg="2">
              <b-button
                @click="selectPersonalItem(personalStage)"
                variant="primary"
                class="mt-2"
                :disabled="!personalStage"
              >
                + Adicionar
              </b-button>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" class="mt-3">
              <b-form-group>
                <label
                  for="contact_name"
                  class="text-center font-weight-bold text-primary"
                >
                  Valor estimado da hora:
                </label>
                <BFormInput
                  placeholder="R$ 0,00"
                  id="contact_name"
                  type="text"
                  v-money="moneyMask"
                  v-model="item.hour_cost"
                ></BFormInput>
              </b-form-group>
            </b-col>

            <b-col cols="12" xs="12" sm="12" md="6" lg="6" class="mt-3">
              <b-form-group>
                <label
                  for="contact_name"
                  class="text-center font-weight-bold text-primary"
                >
                  Alocação prevista de custo direto:
                </label>
                <BFormInput
                  placeholder="R$ 0,00"
                  id="contact_name"
                  type="text"
                  v-money="percentageMask"
                  v-model="item.direct_cost_prediction"
                ></BFormInput>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row
            v-for="(item, index) in selectedItems"
            :key="index"
            class="my-1 m-2 pl-2 pr-2 text-left d-flex justify-content-center"
          >
            <b-col cols="12">
              <h4 class="text-left">FASE {{ index + 1 }} - {{ item.name }}</h4>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="3" lg="3">
              <b-form-group>
                <label
                  for="project_value"
                  class="text-center font-weight-bold text-primary"
                >
                  Valor previsto em horas
                </label>
                <b-input-group>
                  <BFormInput
                    type="text"
                    v-model="stages_data.stage_hour_prediction[index]"
                    v-money="integerMask"
                    placeholder="Ex. 20h"
                  >
                  </BFormInput>
                </b-input-group>
              </b-form-group>
            </b-col>

            <b-col cols="12" xs="12" sm="12" md="3" lg="3">
              <b-form-group>
                <label class="text-center font-weight-bold text-primary">
                  Valor do contrato
                </label>
                <BFormInput
                  placeholder="R$ 0,00"
                  type="text"
                  v-model="
                    stages_data.stage_predicted_direct_cost_revenue[index]
                  "
                  v-money="moneyMask"
                  :value="` ${calculateRevenue(index)}`"
                  disabled
                ></BFormInput>
              </b-form-group>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="3" lg="3">
              <b-form-group>
                <label class="text-center font-weight-bold text-primary">
                  Alocado com custo direto
                </label>
                <BFormInput
                  placeholder="R$ 0,00"
                  type="text"
                  v-model="stages_data.stage_predicted_contract_revenue[index]"
                  v-money="moneyMask"
                  :value="` ${calculateRevenue(index)}`"
                  disabled
                ></BFormInput>
              </b-form-group>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="3" lg="3">
              <b-form-group>
                <label
                  for="contact_name"
                  class="text-center font-weight-bold text-primary"
                >
                  Terminar etapa até:
                </label>
                <b-form-datepicker
                  type="date"
                  v-model="stages_data.stage_due_date[index]"
                  :date-format-options="{
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                  }"
                ></b-form-datepicker>
              </b-form-group>
            </b-col>
          </b-row>
        </b-row>
        <hr />

        <b-card-header>
          <span class="d-flex align-items-center">
            <h3 class="mb-0 ml-50">2. Alocar custos indiretos</h3>
          </span>
        </b-card-header>

        <b-row
          class="my-1 m-2 pl-2 pr-2 text-left d-flex justify-content-center"
        >
          <b-col cols="12" xs="12" sm="12" md="3" lg="3">
            <b-form-group>
              <label class="text-center font-weight-bold text-primary">
                Imposto (%)
              </label>
              <BFormInput
                placeholder="%"
                type="text"
                v-money="percentageMask"
                v-model="item.tax_fee"
                @input="calculateTaxes(item.tax_fee, 1)"
              ></BFormInput>
            </b-form-group>
          </b-col>

          <b-col cols="12" xs="12" sm="12" md="3" lg="3">
            <b-form-group>
              <label class="text-center font-weight-bold text-primary">
                Retenção (%)
              </label>
              <BFormInput
                placeholder="%"
                type="text"
                v-money="percentageMask"
                v-model="item.retention_fee"
                @input="calculateTaxes(item.retention_fee, 2)"
              ></BFormInput>
            </b-form-group>
          </b-col>

          <b-col cols="12" xs="12" sm="12" md="3" lg="3">
            <b-form-group>
              <label class="text-center font-weight-bold text-primary">
                Comissão (%)
              </label>
              <BFormInput
                placeholder="%"
                type="text"
                v-money="percentageMask"
                v-model="item.comission_fee"
                @input="calculateTaxes(item.comission_fee, 3)"
              ></BFormInput>
            </b-form-group>
          </b-col>

          <b-col cols="12" xs="12" sm="12" md="3" lg="3">
            <b-form-group>
              <label class="text-center font-weight-bold text-primary">
                Administrativo (%)
              </label>
              <BFormInput
                placeholder="%"
                type="text"
                v-money="percentageMask"
                v-model="item.administration_fee"
                @input="calculateTaxes(item.administration_fee, 4)"
              ></BFormInput>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row
          class="my-1 m-2 pl-2 pr-2 text-left d-flex justify-content-center"
        >
          <b-col cols="12" xs="12" sm="12" md="3" lg="3">
            <b-form-group>
              <label class="text-center font-weight-bold text-primary">
                Imposto (R$)
              </label>
              <BFormInput
                :key="inputKey"
                placeholder="R$"
                type="text"
                v-money="moneyMask"
                v-model="item.tax_value"
                disabled
              ></BFormInput>
            </b-form-group>
          </b-col>

          <b-col cols="12" xs="12" sm="12" md="3" lg="3">
            <b-form-group>
              <label class="text-center font-weight-bold text-primary">
                Retenção (R$)
              </label>
              <BFormInput
                placeholder="R$"
                type="text"
                v-money="moneyMask"
                v-model="item.retention_value"
                disabled
              ></BFormInput>
            </b-form-group>
          </b-col>

          <b-col cols="12" xs="12" sm="12" md="3" lg="3">
            <b-form-group>
              <label class="text-center font-weight-bold text-primary">
                Comissão (R$)
              </label>
              <BFormInput
                placeholder="R$"
                type="text"
                v-money="moneyMask"
                v-model="item.comission_value"
                disabled
              ></BFormInput>
            </b-form-group>
          </b-col>

          <b-col cols="12" xs="12" sm="12" md="3" lg="3">
            <b-form-group>
              <label class="text-center font-weight-bold text-primary">
                Administrativo (R$)
              </label>
              <BFormInput
                placeholder="R$"
                type="text"
                v-money="moneyMask"
                v-model="item.administration_value"
                disabled
              ></BFormInput>
            </b-form-group>
          </b-col>
        </b-row>

        <b-card-header>
          <span class="d-flex align-items-center">
            <h3 class="mb-0 ml-50">3. Disciplinas e relatórios</h3>
          </span>
        </b-card-header>
        <b-row class="my-1 m-2 pl-2 pr-2 text-right d-flex justify-content-end">
          <b-col cols="12" xs="12" sm="12" md="3" lg="3">
            <b-button @click="addDiscipline()" variant="primary" class="mt-2">
              + Adicionar
            </b-button>
          </b-col>
        </b-row>

        <b-row
          v-for="(discipline, index) in disciplines"
          :key="index"
          class="my-1 m-2 pl-2 pr-2 text-left d-flex justify-content-start"
        >
          <b-col cols="12" xs="12" sm="12" md="3" lg="3">
            <b-form-group>
              <label class="text-center font-weight-bold text-primary">
                Identificação
              </label>
              <BFormInput
                placeholder="Nome"
                type="text"
                v-model="discipline.discipline_name"
              ></BFormInput>
            </b-form-group>
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="3" lg="3">
            <b-form-group>
              <label class="text-center font-weight-bold text-primary">
                Alocar (%)
              </label>
              <BFormInput
                placeholder="%"
                type="text"
                v-money="percentageMask"
                v-model="discipline.percentual"
                @input="calculateDisciplines(1, index)"
              ></BFormInput>
            </b-form-group>
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="2" lg="2">
            <b-form-group>
              <label class="text-center font-weight-bold text-primary">
                Vlr disponível (R$)
              </label>
              <BFormInput
                placeholder="R$"
                type="text"
                v-money="moneyMask"
                v-model="discipline.disponible"
                disabled
              ></BFormInput>
            </b-form-group>
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="2" lg="2">
            <b-form-group>
              <label class="text-center font-weight-bold text-primary">
                1º pagamento (R$)
              </label>
              <BFormInput
                placeholder="R$"
                type="text"
                v-money="moneyMask"
                v-model="discipline.first_payment"
                disabled
              ></BFormInput>
            </b-form-group>
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="2" lg="2">
            <b-form-group>
              <label class="text-center font-weight-bold text-primary">
                2º pagamento (R$)
              </label>
              <BFormInput
                placeholder="R$"
                type="text"
                v-money="moneyMask"
                v-model="discipline.second_payment"
                disabled
              ></BFormInput>
            </b-form-group>
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="6" lg="6">
            <b-form-group>
              <label class="text-center font-weight-bold text-primary">
                Responsável
              </label>
              <BFormInput
                placeholder="Nome"
                type="text"
                v-model="discipline.responsible"
              ></BFormInput>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            xs="12"
            sm="12"
            md="1"
            lg="1"
            class="d-flex align-items-center justify-content-center"
          >
            <b-button @click="removeDiscipline(index)" variant="danger">
              ✖
            </b-button>
          </b-col>
        </b-row>
        <hr />
        <b-card-header>
          <span class="d-flex align-items-center">
            <feather-icon icon="PieChartIcon" size="20" />
            <h2 class="mb-0 ml-50">Resumo da operação de contrato (projeto)</h2>
          </span>
        </b-card-header>
        <b-row class="mt-2 text-left d-flex justify-content-center">
          <b-col cols="12">
            <b-table
              striped
              hover
              small
              :items="table"
              :fields="fields"
              show-empty
              class="w-100"
            >
              <template #empty>
                <b-alert show variant="primary">
                  <p class="text-center">
                    <br />
                    Aguardando os cálculos para prever resultados
                  </p>
                  <hr />
                </b-alert>
              </template>
              <template v-slot:cell(predicted_revenue)="{}">
                {{ numberToMonetary(item.predicted_revenue) }}
              </template>

              <template v-slot:cell(predicted_gross_revenue)="{}">
                {{ numberToMonetary(item.predicted_gross_revenue) }}
              </template>

              <template v-slot:cell(predicted_liquid_revenue)="{}">
                {{ numberToMonetary(item.predicted_liquid_revenue) }}
              </template>
            </b-table>
          </b-col>
        </b-row>
        <b-row
          v-if="item.predicted_liquid_revenue < 0"
          class="my-1 m-2 pl-2 pr-2 text-left d-flex justify-content-center"
        >
          <b-alert show variant="danger">
            <p class="text-center">
              <br />
              Atenção! <br />De acordo com os parâmetros informados o projeto
              está previsto fechar em prejuízo financeiro.
              <strong>Considere diminuir os custos diretos </strong> ou
              <strong>aumente o valor estimado da hora</strong>.
            </p>
            <br />
          </b-alert>
        </b-row>

        <!-- Imposto
        <b-row
          class="my-1 m-2 pl-2 pr-2 text-left d-flex justify-content-center"
        >
          <b-col col xs="12" sm="12" md="6" lg="6">
            <b-form-group>
              <label
                for="taxes"
                class="text-center font-weight-bold text-primary"
              >
                Imposto :
              </label>
              <b-form-select
                id="taxes"
                v-model="item.tax_id"
                :options="taxes"
                value-field="id"
                text-field="name"
                placeholder="Selecione uma taxa"
              ></b-form-select>
            </b-form-group>
          </b-col>
        </b-row> -->
      </b-form>

      <!-- Botoes -->
      <div class="justify-center mt-5">
        <BButton variant="outline" @click="$router.push('/projetos')"
          >Voltar</BButton
        >
        <BButton variant="primary" @click="saveItem" :disabled="alreadyWarned"
          >Salvar</BButton
        >
      </div>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BCard,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BCol,
  BRow,
  BFormRadioGroup,
  // BFormSelect,
  BFormRadio,
  BInputGroup,
  BCardHeader,
  BTable,
  BAlert,
  BFormDatepicker,
} from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from '@validations';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { VBTooltip } from 'bootstrap-vue';

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BCard,
    BFormInput,
    BCol,
    BRow,
    BForm,
    BFormGroup,
    BFormTextarea,
    //BFormSelect,
    BButton,
    BFormRadioGroup,
    BFormRadio,
    BInputGroup,
    ValidationProvider,
    ValidationObserver,
    BCardHeader,
    BTable,
    BAlert,
    BFormDatepicker,
  },
  data: () => ({
    alreadyWarned: false,
    defaultProjectStages: [],
    availableItems: [],
    selectedItems: [],
    disciplines: [],
    project_type_arr: [],
    personalStage: '',
    inputKey: 0,
    item: {
      address: '',
      client_name: '',
      contact_document: '',
      contact_name: '',
      contact_phone: '',
      contract_date: '',
      deadline: 20,
      document: '',
      finish_date: '',
      is_archived: false,
      project_code: '',
      project_description: '',
      project_name: '',
      project_type: '',
      project_value: 0,
      start_date: '',
      tax_id: null,
      workspace_id: '',
      hour_cost: '',
      tax_fee: '',
      retention_fee: '',
      comission_fee: '',
      administration_fee: '',
      tax_value: '',
      retention_value: '',
      comission_value: '',
      administration_value: '',
      direct_cost_prediction: '',
      predicted_revenue: '',
      predicted_contracted_revenue: '',
      predicted_gross_revenue: '',
      predicted_liquid_revenue: '',
      users: [],
      project_type_id: '',
    },
    stages_data: {
      stage_hour_prediction: [],
      stage_predicted_contract_revenue: [],
      stage_predicted_direct_cost_revenue: [],
      stage_name: [],
      stage_due_date: [],
    },
    taxes: [],
    fields: [
      {
        key: 'predicted_revenue',
        label: 'Receita total',
        sortable: false,
      },
      {
        key: 'predicted_gross_revenue',
        label: 'Lucro bruto',
        sortable: false,
      },
      {
        key: 'predicted_liquid_revenue',
        label: 'Lucro líquido',
        sortable: false,
      },
    ],
    table: [
      {
        predicted_revenue: 0,
        predicted_gross_revenue: 0,
        predicted_liquid_revenue: 0,
      },
    ],
    required,
    moneyMask: {
      prefix: 'R$ ',
      suffix: '',
      thousands: '.',
      decimal: ',',
      precision: 2,
      masked: true,
    },
    percentageMask: {
      prefix: '% ',
      suffix: '',
      thousands: '',
      decimal: ',',
      precision: 1,
      masked: false,
    },
    integerMask: {
      prefix: '',
      suffix: 'h',
      thousands: '',
      decimal: '',
      precision: 0,
      masked: false,
    },
  }),

  created() {
    this.getAllTaxes();
    this.getAllProjectType();
    this.getProjectDefaultStages();
    this.getAllUsers();
  },
  mounted() {
    this.availableItems = this.defaultProjectStages;
  },

  computed: {
    calculateRevenue() {
      return (index) => {
        const stageHours = !Number.isInteger(
          this.stages_data.stage_hour_prediction[index]
        )
          ? this.cleanAndConvert(this.stages_data.stage_hour_prediction[index])
          : this.stages_data.stage_hour_prediction[index];
        const hourCost = this.cleanAndConvert(this.item.hour_cost);
        const directCost = this.cleanAndConvert(
          this.item.direct_cost_prediction
        );

        const revenue_with_direct_cost_alloc =
          (stageHours * hourCost * directCost) / 1000;
        const revenue_without_direct_cost_alloc = stageHours * hourCost * 1;

        return (
          (this.stages_data.stage_predicted_contract_revenue[index] =
            this.numberToMonetary(revenue_with_direct_cost_alloc)),
          (this.stages_data.stage_predicted_direct_cost_revenue[index] =
            this.numberToMonetary(revenue_without_direct_cost_alloc))
        );
      };
    },

    calculateDisciplines() {
      return (index, discipline_index) => {
        let sum_percentages = 0;

        let fee_cleanse = this.disciplines[discipline_index].percentual
          ? this.cleanAndConvert(this.disciplines[discipline_index].percentual)
          : 1;
        let sum_predicted_contracted_revenue = 0;

        this.disciplines.map((item) => {
          const cleanedValue = this.cleanAndConvert(item.percentual);
          sum_percentages += cleanedValue;
        });

        if (sum_percentages > 1000) {
          if (!this.alreadyWarned) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Erro',
                text: 'Os percentuais superam 100%',
                icon: 'XIcon',
                variant: 'danger',
              },
            });
            this.alreadyWarned = true;
          }
        } else {
          this.alreadyWarned = false;
        }

        this.stages_data.stage_predicted_contract_revenue.map((item) => {
          const cleanedValue = this.cleanAndConvert(item);
          sum_predicted_contracted_revenue += cleanedValue;
        });

        switch (index) {
          case 1:
            this.disciplines[discipline_index].disponible =
              (sum_predicted_contracted_revenue * fee_cleanse) / 1000;

            this.disciplines[discipline_index].first_payment =
              (sum_predicted_contracted_revenue * fee_cleanse) / 1000 / 2;

            this.disciplines[discipline_index].second_payment =
              (sum_predicted_contracted_revenue * fee_cleanse) / 1000 / 2;

            break;

          default:
            break;
        }
      };
    },

    calculateTaxes() {
      return (item, index) => {
        let fee_cleanse = item ? this.cleanAndConvert(item) : 1;
        let sum_predicted_revenue = 0;
        let sum_predicted_contracted_revenue = 0;

        this.stages_data.stage_predicted_direct_cost_revenue.map((item) => {
          const cleanedValue = this.cleanAndConvert(item);
          sum_predicted_revenue += cleanedValue;
        });

        this.stages_data.stage_predicted_contract_revenue.map((item) => {
          const cleanedValue = this.cleanAndConvert(item);
          sum_predicted_contracted_revenue += cleanedValue;
        });

        switch (index) {
          case 1:
            this.item.tax_value = (sum_predicted_revenue * fee_cleanse) / 1000;
            break;

          case 2:
            this.item.retention_value =
              (sum_predicted_revenue * fee_cleanse) / 1000;
            break;

          case 3:
            this.item.comission_value =
              (sum_predicted_revenue * fee_cleanse) / 1000;
            break;

          case 4:
            this.item.administration_value =
              (sum_predicted_revenue * fee_cleanse) / 1000;
            break;

          default:
            break;
        }

        this.item.predicted_revenue = sum_predicted_revenue;
        this.item.predicted_contracted_revenue =
          sum_predicted_contracted_revenue;

        this.item.predicted_liquid_revenue =
          this.item.predicted_revenue -
          this.item.predicted_contracted_revenue -
          this.item.administration_value -
          this.item.comission_value -
          this.item.retention_value -
          this.item.tax_value;

        this.item.predicted_gross_revenue =
          sum_predicted_revenue - sum_predicted_contracted_revenue;
      };
    },
    currentWorkspace() {
      return this.$store.getters.currentWorkspace;
    },
  },

  methods: {
    async getAllProjectType() {
      this.project_type_arr = [];

      this.$store
        .dispatch('getAllProjectType', {
          workspace_id: this.currentWorkspace.id,
          page: 1,
          perPage: 1000,
          inactive: false,
        })
        .then((resp) => {
          resp.data.map((item) => {
            let obj = {
              id: item.id,
              name: item.name,
            };
            this.project_type_arr.push(obj);
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getAllUsers() {
      this.$store
        .dispatch('getAllWorkspaceMembers', {
          workspace_id: this.currentWorkspace.id,
          inactive: false,
        })
        .then((resp) => {
          this.users = resp;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getAllTaxes() {
      this.taxes = [];
      this.$store
        .dispatch('getAllTaxes', {
          inactive: false,
          workspace_id: this.currentWorkspace.id,
        })
        .then((resp) => {
          if (resp) {
            resp.map((e) => {
              this.taxes.push({ id: e.id, name: e.tax_name });
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getProjectDefaultStages() {
      this.taxes = [];
      this.$store
        .dispatch('getProjectDefaultStages')
        .then((resp) => {
          if (resp) {
            resp.map((e) => {
              this.defaultProjectStages.push({ name: e.name });
            });
            this.availableItems = this.defaultProjectStages;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    selectItem(item) {
      this.availableItems = this.availableItems.filter(
        (availableItem) => availableItem !== item
      );
      this.selectedItems.push(item);
      this.stages_data.stage_name.push(item.name);
    },

    selectPersonalItem(item) {
      this.selectedItems.push({ name: item, created: true });
      this.personalStage = '';
    },
    addDiscipline() {
      this.disciplines.push({
        discipline_name: '',
        percentual: '',
        first_payment: '',
        second_payment: '',
        disponible: '',
        responsible: '',
      });
    },
    removeDiscipline(index) {
      this.disciplines.splice(index, 1);
    },

    deselectItem(item) {
      this.selectedItems = this.selectedItems.filter(
        (selectedItem) => selectedItem !== item
      );

      this.stages_data.stage_hour_prediction.pop();
      this.stages_data.stage_predicted_contract_revenue.pop();
      this.stages_data.stage_predicted_direct_cost_revenue.pop();

      if (!item.created) {
        this.availableItems.push(item);
      }
    },

    cleanAndConvert(value) {
      if (!value) return 0;
      const cleanedValue = value.replace(/[R$\s.,h%]/g, '').replace(',', '.');
      return parseInt(cleanedValue) || 0;
    },

    saveItem() {
      // if (
      //   !this.item.project_code ||
      //   !this.item.project_name ||
      //   !this.item.client_name ||
      //   !this.item.document ||
      //   !this.item.address ||
      //   !this.item.contact_name ||
      //   !this.item.contact_document ||
      //   !this.item.contact_phone ||
      //   !this.item.project_description ||
      //   !this.item.deadline ||
      //   !this.item.start_date ||
      //   !this.item.contract_date ||
      //   !this.item.finish_date ||
      //   !this.item.project_value ||
      //   !this.item.project_type
      // ) {
      //   this.$toast({
      //     component: ToastificationContent,
      //     props: {
      //       title: 'Todos os campos são obrigatórios.',
      //       icon: 'XIcon',
      //       variant: 'danger',
      //     },
      //   });
      //   return;
      // }

      this.stages_data.stage_predicted_direct_cost_revenue =
        this.stages_data.stage_predicted_direct_cost_revenue.map((item) => {
          return this.cleanAndConvert(item);
        });

      this.stages_data.stage_predicted_contract_revenue =
        this.stages_data.stage_predicted_contract_revenue.map((item) => {
          return this.cleanAndConvert(item);
        });

      this.stages_data.stage_hour_prediction =
        this.stages_data.stage_hour_prediction.map((item) => {
          return parseInt(item);
        });

      this.stages_data.stage_due_date = this.stages_data.stage_due_date.map(
        (item) => {
          return item;
        }
      );

      this.$store
        .dispatch('saveProject', {
          ...this.item,
          workspace_id: this.$store.getters.currentWorkspace.id,
          is_archived: false,
          project_value: this.item.predicted_revenue,
          hour_cost: this.cleanAndConvert(this.item.hour_cost),
          tax_fee: this.cleanAndConvert(this.item.tax_fee),
          retention_fee: this.cleanAndConvert(this.item.retention_fee),
          comission_fee: this.cleanAndConvert(this.item.comission_fee),
          administration_fee: this.cleanAndConvert(
            this.item.administration_fee
          ),
          direct_cost_prediction: this.cleanAndConvert(
            this.item.direct_cost_prediction
          ),
          ...this.stages_data,
          project_disciplines: this.disciplines ? this.disciplines : null,
          project_type_id: this.item.project_type_id
        })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Projeto adicionado com sucesso.',
              icon: 'SuccessIcon',
              variant: 'success',
            },
          });
          this.$router.push({
            name: 'projetos',
          });
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Erro ao adicionar projeto.',
              icon: 'XIcon',
              variant: 'danger',
            },
          });
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.justify-center {
  display: flex;
  justify-content: center;
}
td::before {
  float: none !important;
}
ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

ul li:hover {
  color: rgb(0, 38, 87);
}

.frame {
  border: 1px rgb(165, 165, 165) solid;
  border-radius: 5px;
  padding: 10px;
}

.selected-items-container {
  width: 100%;
}

.header {
  font-size: 1.125rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.item-list {
  border: 1px solid #ccc;
  border-radius: 0.375rem;
  padding: 0.5rem;
  height: 10rem;
  overflow-y: auto;

  .placeholder {
    font-style: italic;
    color: #888;
  }

  .item {
    position: relative;
    padding: 0.5rem;
    border-radius: 0.25rem;
    cursor: pointer;
    margin-bottom: 0.25rem;
    background-color: #f9f9f9;

    &:hover {
      background-color: #e9e9e9;
    }

    .pill {
      position: absolute;
      top: 0.25rem;
      right: 0.25rem;
      background-color: #e74c3c;
      color: #fff;
      font-size: 0.875rem;
      padding: 0.25rem 0.5rem;
      border-radius: 1rem;
      cursor: pointer;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: #c0392b;
      }
    }
  }
}
</style>
